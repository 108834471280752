
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as faqjjKwHwl3gHPshJy6Fwl0mtVaKy4g6wmpINdRy4T3GwoMeta } from "/vercel/path0/pages/faq.vue?macro=true";
import { default as menul3XIPgLTv1f1AR_1myIy0ulUz07vBsXG8GcgfVv5LnwMeta } from "/vercel/path0/pages/menu.vue?macro=true";
import { default as indexG_WfXOzFIdmY7nC2wskK9_5LGE8hGwIJyZEyCaXLOuMMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as eventsh_45BSqZEkpnyRbid9L3PCrn_45m350um9KgWcma1gMj6kwMeta } from "/vercel/path0/pages/events.vue?macro=true";
import { default as contact6cZoOGxJBTY9l_45DSf37OWQKprIBU1PffJx7PVn14dCsMeta } from "/vercel/path0/pages/contact.vue?macro=true";
import { default as galleryDLxFKVENimRRVJJ_459ELiRq_45VfjTVrdrzneLKnokQEnkMeta } from "/vercel/path0/pages/gallery.vue?macro=true";
import { default as sitemapnpadVI10GvN_45Y_45o1so1bwWJcyYretVyWs_xkYJE7aFUMeta } from "/vercel/path0/pages/sitemap.vue?macro=true";
import { default as _91_46_46_46page_93B6221gClypHIxjPWduJSW1WcqVmtTSAgv2lPPCKtC3oMeta } from "/vercel/path0/pages/[...page].vue?macro=true";
export default [
  {
    name: "faq___nl",
    path: "/veelgestelde-vragen",
    component: () => import("/vercel/path0/pages/faq.vue")
  },
  {
    name: "faq___en",
    path: "/en/faq",
    component: () => import("/vercel/path0/pages/faq.vue")
  },
  {
    name: "menu___nl",
    path: "/menu",
    component: () => import("/vercel/path0/pages/menu.vue")
  },
  {
    name: "menu___en",
    path: "/en/menu",
    component: () => import("/vercel/path0/pages/menu.vue")
  },
  {
    name: "index___nl",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "events___nl",
    path: "/offerte-aanvragen",
    component: () => import("/vercel/path0/pages/events.vue")
  },
  {
    name: "events___en",
    path: "/en/request-a-quote",
    component: () => import("/vercel/path0/pages/events.vue")
  },
  {
    name: "contact___nl",
    path: "/contact",
    component: () => import("/vercel/path0/pages/contact.vue")
  },
  {
    name: "contact___en",
    path: "/en/contact",
    component: () => import("/vercel/path0/pages/contact.vue")
  },
  {
    name: "gallery___nl",
    path: "/galerij",
    component: () => import("/vercel/path0/pages/gallery.vue")
  },
  {
    name: "gallery___en",
    path: "/en/gallery",
    component: () => import("/vercel/path0/pages/gallery.vue")
  },
  {
    name: "sitemap___nl",
    path: "/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue")
  },
  {
    name: "sitemap___en",
    path: "/en/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue")
  },
  {
    name: "page___nl",
    path: "/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue")
  },
  {
    name: "page___en",
    path: "/en/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue")
  }
]